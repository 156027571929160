export const AUTHROUTES = {
	login: '/auth/employee_login',
	logout: '/auth/logout',
	change_password: '/employee/change_password',
}
export const USERROUTES = {
	name: 'user',
}
export const SETTINGROUTES = {
	name: 'system-settings',
}
export const CATEGORYTRAILS = {
	name: 'category',
	trail: 'trail',
	addTrails: 'category_trail',
}
export const UPLOADROUTES = {
	name: '/image/upload',
}
export const CHARTROUTES = {
	name: '/statistic/users',
	information: '/statistic/information',
}
