import React, { useCallback, useEffect } from 'react'
import { useForm } from '@mantine/form'
import { Flex, Modal, Stack, Textarea, TextInput } from '@mantine/core'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useModal } from 'src/context/ModalContext'
import { createCategoryTrails } from 'src/apis/categoryTrailsAPI'
import { useLoading } from 'src/context/LoadingContext'

interface ModalCreateCategoryTrailsProps {
	open: boolean
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}

function ModalCreateCategoryTrails({
	open,
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
}: ModalCreateCategoryTrailsProps) {
	const { openLoadingContext, closeLoadingContext } = useLoading()

	const form = useForm({
		initialValues: {
			title: '',
			description: '',
		},

		validate: {
			title: (value) => {
				if (!value.trim()) return 'Title cannot be empty'
				if (value.trim().length < 2 || value.length > 50)
					return 'Title must be 2-50 characters long'
				return null
			},
			description: (value) => {
				if (value.length > 255)
					return 'Description must be under 255 characters long'
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const { openModal, closeModal, openError } = useModal()
	const handleCreateCategoryTrails = useCallback(async (body) => {
		openLoadingContext()
		try {
			const res = await createCategoryTrails(body)
			if (res?.data?.code === 200) {
				if (pagination.pageIndex === 1) {
					await handleGetData()
				} else {
					await handleChangePage(1)
				}

				openModal({
					type: 'success',
					label: 'Category has been created successfully ✔️✔️',
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreateCategoryTrails.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSubmit = useCallback(async (values) => {
		const body = {
			...values,
		}
		openModal({
			type: 'confirm',
			label: 'Do you want to create category ?',
			handleClose: () => closeModal(),
			handleConfirm: () => {
				handleCreateCategoryTrails(body)
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {}, [])
	return (
		<Modal
			centered
			sx={{
				'.mantine-Modal-overlay': {
					zIndex: 402,
				},
				'.mantine-Modal-inner ': {
					width: '100%',
					zIndex: 403,
					padding: 200,
					boxSizing: 'border-box',
					'@media (max-width:900px)': {
						padding: 20,
					},
				},
			}}
			opened={open}
			onClose={onClose}
		>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Stack spacing="0.75rem">
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						Create category
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="title"
						label={'Title'}
						name={'title'}
						placeholder={"Category's title"}
						maxLength={50}
						{...form.getInputProps('title')}
					/>
					<Textarea
						autoComplete="off"
						key="description"
						label={'Description'}
						name={'name'}
						placeholder={"Category's description"}
						maxLength={255}
						autosize
						{...form.getInputProps('description')}
					/>
					<CustomButton variant="outlined" type="submit">
						Create
					</CustomButton>
				</Stack>
			</form>
		</Modal>
	)
}

export default React.memo(ModalCreateCategoryTrails)
